/* BookingForm.css */
.booking-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  margin-bottom: 100px;
  width: 100%;
}

.banner-image-logo{
  width: 30%;
}

.banner-image-logo-right{
  margin-left: 40%;
}

.banner-image {
  width: 100%;
  /* Ensure the image spans the full width of the container */
  height: 340px;
  /* Set the height of the banner image */
  object-fit: cover;
  /* Cover mode ensures the image covers the entire container */
  object-position: center;
  /* Center the image within the container */
}

.intro-text {
  font-size: large;
}

.booking-form {
  display: flex;
  flex-direction: column;
  width: 300px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.booking-form input,
.booking-form select {
  margin-bottom: 15px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.booking-form button {
  padding: 10px;
  background-color: #e74c3c;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}

.booking-form button:hover {
  background-color: #c0392b;
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #333;
  color: #fff;
  text-align: center;
  padding: 0;
}
.container {
  padding: 20px;
}

.dateGroup {
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.dateHeader {
  font-size: 1.5em;
  margin-bottom: 10px;
  color: #333;
}

.list {
  list-style: none;
  padding: 0;
}

.listItem {
  padding: 5px 0;
  border-bottom: 1px solid #eee;
}

.bookingInfo {
  display: flex;
  justify-content: space-between;
}
